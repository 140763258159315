import React from 'react';

const ProductAd1 = () => {
    const products=[
        { id: 1, 
            img:"../../../images/home/01.jpg",
            title:"Sweater's Apparels",
            subTitle:"All Custom Design, Color, Size, Logos",
            description:"Highest Quality-Fast Turnaurnd-Export Sales Support",
            imgOrder:1,
            contentOrder:2
        },
        { id: 2, 
            img:"../../../images/home/02.jpg",
            title: "Woven's Apparels",
            subTitle:"All Custom Design, Color, Size, Logos",
            description:"Highest Quality-Fast Turnaurnd-Export Sales Support",
            imgOrder:2,
            contentOrder:1
        },
        { id: 3, 
            img:"../../../images/home/03.jpg",
            title: "Denim's Apparels",
            subTitle:"All Custom Design, Color, Size, Logos",
            description:"Highest Quality-Fast Turnaurnd-Export Sales Support",
            imgOrder:1,
            contentOrder:2
        },
        { id: 4, 
            img:"../../../images/home/04.jpg",
            title: "Knit's Apparels",
            subTitle:"All Custom Design, Color, Size, Logos",
            description:"Highest Quality-Fast Turnaurnd-Export Sales Support",
            imgOrder:2,
            contentOrder:1
        },
        { id: 5, 
            img:"../../../images/home/05.jpg",
            title: "Under Garments's Apparels",
            subTitle:"All Custom Design, Color, Size, Logos",
            description:"Highest Quality-Fast Turnaurnd-Export Sales Support",
            imgOrder:1,
            contentOrder:2
        },
    ]
    return (

        <>
        {
            products?.map(item => {
                return(
                    <>
                        <section className='flex max-w-screen-xl mx-auto px-6 justify-around' key={item?.id}>

                            <div className={`w-1/2 h-96 order-${item?.imgOrder}`}>
                                <img className='w-full h-full object-cover' src={item?.img} alt={item?.title} />
                            </div>

                            <div className={`flex flex-col items-center justify-center order-${item?.contentOrder}`}>
                                <h1 className="cursive_font text-3xl text-indigo-700 ">{item?.title}</h1>

                                <h3 className='font-semibold pt-6 text-xl'>{item?.subTitle}</h3>
                                <span className='text-gray-500 text-sm'>{item?.description}</span>
                            </div>
                        </section>
                    </>
                )
            })
        }
        
        </>
        
    );
};

export default ProductAd1;