import React from 'react';

const ServiceCard = ({icon,text}) => {
    return (
        <div className='bg-white rounded-lg p-4 py-8 box-border flex space-y-4 flex-col items-center justify-center h-full'>
            {icon}
            <span className='text-gray-600 text-center font-semibold'>{text}</span>
        </div>
    );
};

export default ServiceCard;