import React from 'react';

const Whychose = () => {


    return (
        <section className='max-w-screen-lg mx-auto py-16 px-6'>
            {/* title */}

            <div className='flex flex-row items-center justify-center'>
                <h1 className='cursive_font text-4xl text-indigo-700 font-bold '> Why Choose MNS Apparels Ltd. </h1>

            </div>

            {/* contents  */}
            <div className='flex flex-row items-center justify-center pt-10 space-x-8'>

                <div className='flex flex-col space-y-4'>
                    {/* text  */}
                    {/* Advantages of MNS Apparels Ltd. */}

                    <div className='bg-white shadow-lg p-8 rounded-lg'> 
                        <h1 className='text-2xl cursive_font text-gray-800'>Advantages of MNS Apparels Ltd.</h1>
                        <ol className="list-decimal pt-4 text-gray-600">
                            <li>Global buyers across many countries</li>
                            <li>30+ years of export experience Logistics, Banking, Administration
                            </li>
                            <li>Experienced, Highly Educated & Diverse Management Team
                            </li>
                            <li>Clean Reputation in Bangladesh
                            </li>
                        </ol>
                    </div>

                    {/* Services of MNS Apparels Ltd. */}
                    <div className='bg-white shadow-lg p-8 rounded-lg'>
                        <h1 className='text-2xl cursive_font text-gray-800'>Services of MNS Apparels Ltd.</h1>

                        <ol className="list-decimal pt-4 text-gray-600">
                            <li>Sourcing</li>
                            <li>Sample Development</li>
                            <li>Merchandising</li>
                            <li>Quality Control</li>
                            <li>Inspection and Shipment</li>
                        </ol>
                    </div>

                </div>

                {/* images  */}
                <div className='h-96 w-96'>
                    <img src="../../../images/map.jpg" alt="map" />
                </div>
            </div>

        </section>
    );
};

export default Whychose;