import React from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { BsFillTrophyFill } from 'react-icons/bs';
import { FaCalculator, FaPenNib } from 'react-icons/fa';
import { GiArchiveResearch } from 'react-icons/gi';
import { MdOutlineAnalytics, MdProductionQuantityLimits } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import "../../styles/service_section.css";
import ServiceCard from './ServiceCard';

const ServiceSection = () => {

    const services = [
        { id: 1, icon: <GiArchiveResearch  className='text-5xl text-indigo-700' />, text: "Research & Development" },
        { id: 2, icon: <FaPenNib  className='text-5xl text-indigo-700'/>, text: "Design" },
        { id: 3, icon: <FaCalculator  className='text-5xl text-indigo-700'/>, text: "Costing & Quote Reviews" },
        { id: 4, icon: <MdOutlineAnalytics className='text-5xl text-indigo-700' />, text: "Analysis" },
        { id: 5, icon: <AiFillClockCircle className='text-5xl text-indigo-700' />, text: "Time and Action Plan" },
        { id: 6, icon: <MdProductionQuantityLimits className='text-5xl text-indigo-700' />, text: "Production" },
        {
            id: 7, icon: <TiTick className='text-5xl text-indigo-700' />, text: "Quality Control"
        },
        {
            id: 8, icon: <BsFillTrophyFill className='text-5xl text-indigo-700'  />, text: "Operations Support"
        },
    ]

    return (
        <section className='max-w-screen-lg mx-auto py-12 px-6 service_section'>

            <div className='max-w-screen-lg mx-auto px-6 py-16'>


                {/* heading  */}
                <div className='flex flex-col items-center justify-center space-y-2'>
                    <h1 className='text-white text-4xl font-bold cursive_font'>Our Services</h1>
                    <p className='text-gray-300 text-sm'>Factors that make us compelling in the eyes of our clients. At MNS Apparels Ltd. we believe superior service begets superior success.</p>
                </div>


                {/* service card  */}
                <div className='grid grid-cols-4 gap-8 pt-12'>
                    {services?.map(item => {
                        return(
                            <div key={item?.id}>
                                <ServiceCard icon={item?.icon} text={item?.text} />
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    );
};

export default ServiceSection;