import React from 'react';
import AboutSection from '../components/Home Page Components/AboutSection';
import HeroBanner from '../components/Home Page Components/HeroBanner';
import OurProduct from '../components/Home Page Components/OurProduct';
import ProductAd1 from '../components/Home Page Components/ProductAd1';
import ServiceSection from '../components/Home Page Components/ServiceSection';
import StatsSection from '../components/Home Page Components/StatsSection';
import Whychose from '../components/Home Page Components/Whychose';

const HomePage = () => {
    return (
        <main>
           <HeroBanner /> 
           <AboutSection />
           <ServiceSection />
           <Whychose />
           <ProductAd1 />
           <OurProduct />
           <StatsSection />
        </main>
    );
};

export default HomePage;