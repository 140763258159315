import React from 'react';

const AboutSection = () => {
    return (
        <section className='max-w-screen-lg mx-auto py-12 px-6'>

            {/* heading  */}
            <div className='flex flex-col items-center'>
                <h1 className='cursive_font text-4xl text-indigo-700 font-bold '> Welcome to MNS Apparels Ltd </h1>
                <p className='text-black font-semibold pt-4'>Since last 5 years we are successfully doing business with our EUROPEAN/CANADIAN/U.S.A buyers.</p>
                <p className='text-gray-700'>As a true professional company we efficiently meet demand of our all clients.</p>
            </div>


            {/* about - images and description  */}
            <div className='grid grid-cols-5 gap-x-12 pt-12'>
                <div className='w-96 h-72 col-span-2  '>
                    <img className='border border-gray-200 rounded-md p-2 w-full h-full object-cover box-border' src="../../../images/banner/5.jpg" alt="fhfdh" />
                </div>

                {/* text  */}
                <div className='col-span-3 text-justify flex flex-col space-y-3 text-gray-700'>
                    <p>MNS Apparels Ltd. Is a 100% export oriented readymade garments Buying & manufacturer and exporter location at Dhaka, capital of Bangladesh. Our mission is to manufacture and to provide high quality of readymade garments with in-time delivery/shipment to our valuable customers around the world.</p>

                    <p>Since last 5 years we are successfully doing business with our EUROPEAN/CANADIAN/U.S.A buyers.</p>

                    <p>Quality is our main goal and we strongly monitor the production at every stage to ensure the best quality to enroll our very best relation with our all BUYERS. We commit to execute every order with the dedication and perfection towards achieving quality and timely delivery of our orders.</p>

                    <p>As a true professional company we efficiently meet demand of our all clients.</p>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;