import React, { useState } from 'react';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { VscChromeClose } from 'react-icons/vsc';
// import Fade from 'react-reveal/Fade';
import { Link, useNavigate } from "react-router-dom";
import styles from '../styles/navbar.module.css';

const Navbar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [header, setHeader] = useState(false)
    const navigate = useNavigate()
    const items = [
        { name: 'Home', to: '/#home' },
        { name: 'About Us', to: '/about' },
        { name: 'Clients', to: '/clients' },
        { name: 'Products', to: '/products' },
        { name: 'Contact', to: '/contact' },
    ];


    const changeHeader = () => {
        if (window.scrollY >= 80) {
            setHeader(true)
        } else {
            setHeader(false)
        }
    }

    window.addEventListener('scroll', changeHeader)


    return (
        <header className={` ${header ? "bg-indigo-700 shadow-lg ransition duration-500" : `${styles.nav}`}  z-50 fixed top-0 left-0 w-full  `} id="home">

            <div className={`max-w-screen-2xl mx-auto px-6 py-6`}>

                <div className="flex gap-x-4 justify-between items-center">

                    {/* <Link to="/"> */}

                    <div className="flex items-center">

                        <h1 className='text-white text-3xl' onClick={() => navigate("/")}>MNS Apparels Ltd</h1>
                        {/* <img className={`${header ? "scale-95 w-40 p-2 transition duration-500 " : "scale-100 w-40 transition duration-500 "} object-contain cursor-pointer`} src="../../images/logo.png" alt="brand" onClick={() => navigate("/")} /> */}
                    </div>

                    {/* </Link> */}

                    {/*========== dynamic icon ==========*/}

                    <button onClick={() => setMobileMenu(!mobileMenu)} className="lg:hidden p-2 transition duration-500 rounded-lg">

                        {
                            mobileMenu ? <VscChromeClose className="text-2xl text-white" /> : <HiOutlineMenuAlt3 className="text-2xl text-white" />
                        }

                    </button>


                    <div className="hidden lg:flex items-center gap-x-5">

                        <nav className="flex items-center gap-x-6">
                            {items?.map((item) => {
                                return (
                                    <Link to={item?.to} key={item?.name}>
                                        <span className='cursive_font text-white text-xl cursor-pointer hover:text-primary hover:translate-y-2 transition duration-500 select-none'>{item?.name}</span>
                                    </Link>
                                )
                            })}

                            {/* {
                                items?.map((item, index) => <NavLink className={(info) => info.isActive ? "text-green-600 font-bold border-b-2 border-green-600" : "text-white"} key={index} to={item?.to}>

                                    <h1>{item?.name}</h1>

                                </NavLink>)
                            } */}
                            {/* <AiOutlineSearch className='text-lg cursor-pointer' /> */}

                        </nav>

                        <div>


                        </div>

                    </div>

                </div>

                {/*============== mobile menu =============*/}

                {

                    mobileMenu && <div>

                        <div className="lg:hidden flex flex-col bg-indigo-700  p-6 box-border ">
                            {items?.map((item) => {
                                return (
                                    <Link to={item?.to} key={item?.name} className='text-white text-sm py-2 hover:translate-x-2 transition  duration-500 linear cursor-pointer '>
                                        <span >{item?.name}</span>
                                    </Link>
                                )
                            })}

                            {/* {
                                items?.map((item, index) => <NavLink className={(info) => info.isActive ? "text-green-600 font-bold border-b-2 border-green-600" : ""} key={index} to={item?.to}>

                                    <h1 className="px-2 py-2">{item?.name}</h1>

                                </NavLink>)
                            } */}

                            {/* search btn  */}
                            {/* <div className='py-3 text-xl'>
                                <AiOutlineSearch className='text-gray-600 cursor-pointer' />

                            </div> */}

                        </div>

                    </div>

                }

            </div>

        </header>
    );
};

export default Navbar;