import React from 'react';

const OurProduct = () => {
    return (
        <main className='bg-gray-900 mt-8'>
            <section className='max-w-screen-lg mx-auto py-12 px-6'>

                {/* heading  */}
                <div className='flex flex-col items-center'>
                    <h1 className='cursive_font text-4xl text-white font-bold '> Our Products </h1>
                </div>

                {/* photo gallery  */}

                <div className='py-8'>

                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div className="grid gap-4">
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clNameass="h-auto max-w-full rounded-lg" src="../../../images/products/1.jpg" alt="pd1" />
                                <p className='text-white text-sm text-center pt-3'>Denim's Apparels</p>
                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/2.jpg" alt="pd2" />
                                <p className='text-white text-sm text-center pt-3'>Woven's Apparels</p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/6.jpg" alt="pd6" />
                                <p className='text-white text-sm text-center pt-3'>Under Garments's Apparels</p>

                            </div>

                        </div>
                        <div className="grid gap-4">
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/3.jpg" alt="pd3" />
                                <p className='text-white text-sm text-center pt-3'> Knit's Apparels</p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/4.jpg" alt="pd4" />
                                <p className='text-white text-sm text-center pt-3'>Children Woven Apparels</p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/5.jpg" alt="pd5" />
                                <p className='text-white text-sm text-center pt-3'> Sweater's Apparels </p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/10.jpg" alt="pd10" />
                                <p className='text-white text-sm text-center pt-3'> Sweater's Apparels </p>

                            </div>

                        </div>
                        <div className="grid gap-4">
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/7.jpg" alt="pd7" />
                                <p className='text-white text-sm text-center pt-3'>Children Sweater's Apparels</p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/8.jpg" alt="pd8" />
                                <p className='text-white text-sm text-center pt-3'>Denim's Apparels</p>

                            </div>
                            <div className='bg-gray-700 pb-4 rounded-lg overflow-hidden'>
                                <img clasNames="h-auto max-w-full rounded-lg" src="../../../images/products/9.jpg" alt="pd9" />
                                <p className='text-white text-sm text-center pt-3'>Denim's Apparels</p>

                            </div>
                        </div>
                    </div>

                </div>


            </section>
        </main>
    );
};

export default OurProduct;