import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "../../styles/herobanner.css";
// import required modules
import { Navigation, Pagination } from "swiper";


const HeroBanner = () => {

    const images=[
        {id:1,img:"../../../images/banner/1.jpg",alt:"gsg"},
        {id:2,img:"../../../images/banner/2.jpg",alt:"gsdg"},
        {id:3,img:"../../../images/banner/3.jpg",alt:"dg"},
        {id:4,img:"../../../images/banner/4.jpg",alt:"gshfhdfg"},
        {id:5,img:"../../../images/banner/5.jpg",alt:"gsjdg"},
    ]
    return (
        <main>
            <section className='hero_section'>
                <Swiper
                    slidesPerView={1}
                    // spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {images?.map(item=> {
                        return(
                            <>
                                <SwiperSlide key={item?.id}>
                                    <img className='w-full h-full' src={item?.img} alt={item?.alt} />
                                </SwiperSlide>

                            </>
                        )
                    })}
                </Swiper>  
            </section>
        </main>
    );
};

export default HeroBanner;