import React from 'react';
import { AiOutlineFileDone, AiOutlineUser } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { MdOutlineFactory } from 'react-icons/md';

const StatsSection = () => {
    return (
        <section className="bg-indigo-700 py-6">
            <div className='max-w-screen-lg mx-auto px-6 py-12'>

                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-col items-center justify-center space-y-3'>
                        <AiOutlineFileDone className='text-white font-bold text-7xl' />
                        <h1 className="text-4xl text-white font-bold">5+</h1>
                        <span className='text-lg text-white'>Years in Business</span>
                    </div>

                    <div className='flex flex-col items-center justify-center space-y-3'>
                        <AiOutlineUser className='text-white font-bold text-7xl' />
                        <h1 className="text-4xl text-white font-bold">100+</h1>
                        <span className='text-lg text-white'>Total Manpower</span>
                    </div>

                    <div className='flex flex-col items-center justify-center space-y-3'>
                        <MdOutlineFactory className='text-white font-bold text-7xl' />
                        <h1 className="text-4xl text-white font-bold">25+</h1>
                        <span className='text-lg text-white'>Sourcing Factories</span>
                    </div>

                    <div className='flex flex-col items-center justify-center space-y-3'>
                        <FiUsers className='text-white font-bold text-7xl' />
                        <h1 className="text-4xl text-white font-bold">5,000+</h1>
                        <span className='text-lg text-white'>Number Of Workers</span>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default StatsSection;